import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useBrandsStore = defineStore('brand-store', () => {

  const gamas = ref([]);
  const selectedBrand = ref('');
  const loadedGama = ref('');
  const gamaTipo = ref('NUE');
  const selectedGama = ref(''); 
  const vehiculoReserva = ref({});

  const getGamaTurismos = computed(() => {
    return gamas.value.filter(gama => gama.tipo_vehiculo === 'NUE');
  });

  const getGamaComerciales = computed(() => {
    return gamas.value.filter(gama => gama.tipo_vehiculo === 'COM');
  });

  const getGamaMotos = computed(() => {
    return gamas.value.filter(gama => gama.tipo_vehiculo === 'MOT');
  });

  function setGamas(payload){
    gamas.value = payload;
  }

  function setGamaTipo(payload){
    gamaTipo.value = payload;
  }

  function setSelectedBrand(payload){
    selectedBrand.value = payload;
  }

  function setSelectedGama(payload){
    selectedGama.value = payload;
  }

  function setVehiculoReserva(payload){
    vehiculoReserva.value = payload;
  }

  function setLoadedGama(payload){
    loadedGama.value = payload;
  }

  return {
    loadedGama, 
    gamas,
    selectedBrand,
    setLoadedGama,
    selectedGama,
    gamaTipo,
    getGamaTurismos,
    getGamaComerciales,
    getGamaMotos,
    vehiculoReserva,
    setGamas,
    setSelectedBrand,
    setSelectedGama,
    setVehiculoReserva,
    setGamaTipo
  };
});